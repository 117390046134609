import React from "react"
import { defaultStaticContext } from '../context/StaticContext'
import { useTranslation } from "react-i18next"

const Countdown = () => {
  const addZero = (num, length) => {
    for(var len = (num + "").length; len < length; len = num.length) {
      num = "0" + num;            
    }
    return num;
  }
  const calculateTimeLeft = () => {
    const difference = + new Date(defaultStaticContext.startDate) - + new Date();
    let timeLeft = {};
  
    if (difference > 0) {
      timeLeft = {
        days: addZero(Math.floor(difference / (1000 * 60 * 60 * 24)), 2),
        hours: addZero(Math.floor((difference / (1000 * 60 * 60)) % 24), 2),
        minutes: addZero(Math.floor((difference / 1000 / 60) % 60), 2),
        seconds: addZero(Math.floor((difference / 1000) % 60), 2)
      };
    }
    return timeLeft;
  };

  const { t } = useTranslation()

  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft());

  React.useEffect(() => {
    const timer = setInterval(() => setTimeLeft(calculateTimeLeft()), 1000);
    return () => clearInterval(timer);
  }, []);

  const timerComponents = [];

  const times = ['days', 'hours', 'minutes', 'seconds']

  Object.keys(timeLeft).forEach((interval, index) => {
    if (!timeLeft[interval]) {
      return;
    }
    timerComponents.push(
      <div key={index}>
        <div className="countdown__item">
          <span className="countdown__number">{timeLeft[interval]}</span>
          <span className="countdown__name">{ times[index] }</span>
        </div>
        <span className="countdown__break">{(index === Object.keys(timeLeft).length -1) ? "" : ":"}</span>
      </div>
    );
  });

  return (
    <div className="countdown">
      <div className="countdown__desc">
        { t('signup.start') }
      </div>
      <div className="countdown__time">
        {timerComponents.length ? timerComponents : <span>Time's up!</span>}
      </div>
    </div>
  )
}

export default Countdown
