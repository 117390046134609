import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { TextField, Button } from '@material-ui/core';
import MailchimpSubscribe from "react-mailchimp-subscribe"
import Countdown from "../components/countdown"
import Image from "../components/image"
import { useTranslation } from "react-i18next"
import countdown from '../images/countdown.gif'
import { useSelector } from 'react-redux'

const SignupPage = () => {
  const currentRegion = useSelector(state => state.currentRegion)
  const currentLang = useSelector(state => state.currentLang)
  const url = "https://createmygregory.us19.list-manage.com/subscribe/post?u=0475c79cb341f5e73b08648c2&amp;id=ebb71c8724";
  const emailRef = React.useRef(null);
  const CustomForm = ({ status, message, onValidated }) => {
    const submit = () => {
      // emailRef.current &&
      // emailRef.current.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: emailRef.current.value,
        LANGUAGE: currentLang,
        REGION: currentRegion
      });
    }
    
    // const stripHtml = html => {
    //   var tmp = document.createElement("DIV");
    //   tmp.innerHTML = html;
    //   return tmp.textContent || tmp.innerText || "";
    // }

    const { t } = useTranslation()
    
    return (
      <>
        <img className="signupPage__image" src={countdown} />
        <Countdown />
        { status === "sending" ? (
          <div className="signupPage__msg">
            <span>{ t('signup.defaultText') }</span>
            <div style={{ color: `grey` }}>{ t('signup.sending') }</div>
          </div>
        ) : status === "success" ? (
          <div className="signupPage__msg">
            <span>{ t('signup.successText') }</span>
          </div>
        ) : status === "error" ? (
          <div className="signupPage__msg">
            <span>{ t('signup.defaultText') }</span>
            <span style={{ color: `red` }}>{message[0] === '0' ? t('signup.errorText1') : t('signup.errorText2')}</span>
          </div>
        ) : (
          <div className="signupPage__msg">
            <span>{ t('signup.defaultText') }</span>
          </div>
        )}
        {status === "success" ? (
          <div className="signupPage__form">
            {/* <Link className="basicButton" to="/story-wall">
              <span>{ t('signup.explore') }</span>
            </Link> */}
          </div>
        ) : (
          <div className="signupPage__form">
            <TextField
              error={status === "error"}
              id="subscript"
              label={ t('signup.email') }
              variant="outlined"
              inputRef={emailRef}
              fullWidth
            />
            <div className="buttonGroup stickToBottom">
              <Button className="basicButton" onClick={submit}>
                { t('signup.signup') }
              </Button>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="signupPage">
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={formData => subscribe(formData)}
          />
        )}
      />
    </div>
  )
}
  

export default SignupPage
